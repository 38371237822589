export const customListPractitioners = /* GraphQL */ `
  query ListPractitioners($locationIds: [ID], $serviceName: String) {
    listPractitioners(locationIds: $locationIds, serviceName: $serviceName) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      description
      mobile
      email
      profilePicture
      jobTitle
      primaryLocation {
        id
        name
        code
      }
      currentLocation {
        id
        name
        code
      }
      allowedLocations {
        id
        name
        code
      }
      createdAt
      createdBy
      identityNumber
      identityType
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      onboardDate
      resignationDate
      isActive
      roles {
        id
        name
        description
    }
  }
`;

export const customLoggedInUser = /* GraphQL */ `
  query GetLoggedInUser {
    getLoggedInUser {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      isActive
      jobTitle
      profilePicture
      email
      mobile
      onboardDate
      primaryLocationId
      # primaryLocation {
      #   id
      #   name
      #   code
      #   addressStreetAddress
      #   addressUnitNo
      #   addressCountry
      #   addressPostalCode
      #   taxStatus
      #   registeredName
      #   registrationNumber
      #   operatingHours
      #   telephones
      #   whatsapp
      #   emails
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      # allowedLocations {
      #   id
      #   name
      #   code
      #   addressStreetAddress
      #   addressUnitNo
      #   addressCountry
      #   addressPostalCode
      #   taxStatus
      #   registeredName
      #   registrationNumber
      #   operatingHours
      #   telephones
      #   whatsapp
      #   emails
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      # roles {
      #   id
      #   name
      #   description
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      timezone
      country
      viewOnlyMemberCalendars
      kioskOnlineBookingPageId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListUsers = /* GraphQL */ `
  query ListUsers($locationIds: [ID!], $type: String) {
    listUsers(locationIds: $locationIds, type: $type) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      currentLocationId
      allowedLocationIds
      # roles {
      #   id
      #   name
      #   description
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      # services {
      #   id
      #   locationId
      #   practitionerId
      #   name
      #   code
      #   description
      #   category
      #   duration
      #   workingHours
      #   isActive
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
    }
  }
`;

export const customListUsersOnLoad = /* GraphQL */ `
  query ListUsers($locationIds: [ID!], $type: String) {
    listUsers(locationIds: $locationIds, type: $type) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      currentLocationId
      allowedLocationIds
      country
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      onboardDate
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
