/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIamUser = /* GraphQL */ `
  mutation CreateIamUser($input: CreateIamUserInput!) {
    createIamUser(input: $input) {
      id
      username
      description
      roles {
        nextToken
      }
      policies {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createIamRole = /* GraphQL */ `
  mutation CreateIamRole($input: CreateIamRoleInput!) {
    createIamRole(input: $input) {
      id
      name
      description
      users {
        nextToken
      }
      policies {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createIamPolicy = /* GraphQL */ `
  mutation CreateIamPolicy($input: CreateIamPolicyInput!) {
    createIamPolicy(input: $input) {
      id
      name
      description
      documents {
        effect
        resources
        actions
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateIamPolicy = /* GraphQL */ `
  mutation UpdateIamPolicy($input: UpdateIamPolicyInput!) {
    updateIamPolicy(input: $input) {
      id
      name
      description
      documents {
        effect
        resources
        actions
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createIamUserRoles = /* GraphQL */ `
  mutation CreateIamUserRoles($input: CreateIamUserRolesInput!) {
    createIamUserRoles(input: $input) {
      id
      iamUserId
      iamRoleId
      iamUser {
        id
        username
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      iamRole {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createIamUserPolicies = /* GraphQL */ `
  mutation CreateIamUserPolicies($input: CreateIamUserPoliciesInput!) {
    createIamUserPolicies(input: $input) {
      id
      iamUserId
      iamPolicyId
      iamUser {
        id
        username
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      iamPolicy {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createIamRolePolicies = /* GraphQL */ `
  mutation CreateIamRolePolicies($input: CreateIamRolePoliciesInput!) {
    createIamRolePolicies(input: $input) {
      id
      iamRoleId
      iamPolicyId
      iamRole {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      iamPolicy {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      id
      fullName
      isInitialized
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      preferredProviderIds
      preferredProviders {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      medicalProfile {
        id
        noKnownDrugAllergy
        noKnownFoodAllergy
        noKnownOtherAllergy
        drugAllergies
        foodAllergies
        otherAllergies
        vaccines
        insurance
        subsidySchemes
        medicalConditions
        noKnownMedicalConditions
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        id
        referredBy
        referralSource
        referralCategory
        consent
        membershipTier
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      strictlyProviders
      customerShortCode
      profilePicture
      quickbooksId
      extraData {
        completedAppointmentsCount
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updatePatientFull = /* GraphQL */ `
  mutation UpdatePatientFull($input: UpdatePatientFullInput!) {
    updatePatientFull(input: $input) {
      id
      fullName
      isInitialized
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      preferredProviderIds
      preferredProviders {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      medicalProfile {
        id
        noKnownDrugAllergy
        noKnownFoodAllergy
        noKnownOtherAllergy
        drugAllergies
        foodAllergies
        otherAllergies
        vaccines
        insurance
        subsidySchemes
        medicalConditions
        noKnownMedicalConditions
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        id
        referredBy
        referralSource
        referralCategory
        consent
        membershipTier
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      strictlyProviders
      customerShortCode
      profilePicture
      quickbooksId
      extraData {
        completedAppointmentsCount
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient($patientId: ID!) {
    deletePatient(patientId: $patientId) {
      id
      fullName
      isInitialized
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      preferredProviderIds
      preferredProviders {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      medicalProfile {
        id
        noKnownDrugAllergy
        noKnownFoodAllergy
        noKnownOtherAllergy
        drugAllergies
        foodAllergies
        otherAllergies
        vaccines
        insurance
        subsidySchemes
        medicalConditions
        noKnownMedicalConditions
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        id
        referredBy
        referralSource
        referralCategory
        consent
        membershipTier
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      strictlyProviders
      customerShortCode
      profilePicture
      quickbooksId
      extraData {
        completedAppointmentsCount
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createRecurringAppointments = /* GraphQL */ `
  mutation CreateRecurringAppointments($input: CreateAppointmentInput) {
    createRecurringAppointments(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment($input: CreateAppointmentInput) {
    createAppointment(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment($input: UpdateAppointmentInput) {
    updateAppointment(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updateConfirmStatusAppointment = /* GraphQL */ `
  mutation UpdateConfirmStatusAppointment(
    $appointmentId: ID!
    $isConfirmed: Boolean!
    $newStatus: AppointmentStatus
    $timestamp: AWSDateTime
  ) {
    updateConfirmStatusAppointment(
      appointmentId: $appointmentId
      isConfirmed: $isConfirmed
      newStatus: $newStatus
      timestamp: $timestamp
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updateCancelStatusAppointment = /* GraphQL */ `
  mutation UpdateCancelStatusAppointment(
    $appointmentId: ID!
    $isCancelled: Boolean!
    $cancellationNote: String
    $newStatus: AppointmentStatus
    $timestamp: AWSDateTime
    $unlinkAppointmentPlan: Boolean
    $sendNotification: Boolean
  ) {
    updateCancelStatusAppointment(
      appointmentId: $appointmentId
      isCancelled: $isCancelled
      cancellationNote: $cancellationNote
      newStatus: $newStatus
      timestamp: $timestamp
      unlinkAppointmentPlan: $unlinkAppointmentPlan
      sendNotification: $sendNotification
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updateCheckinStatusAppointment = /* GraphQL */ `
  mutation UpdateCheckinStatusAppointment(
    $appointmentId: ID!
    $isCheckin: Boolean!
    $newStatus: AppointmentStatus
    $timestamp: AWSDateTime
  ) {
    updateCheckinStatusAppointment(
      appointmentId: $appointmentId
      isCheckin: $isCheckin
      newStatus: $newStatus
      timestamp: $timestamp
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment($appointmentId: ID!) {
    deleteAppointment(appointmentId: $appointmentId) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updateAppointmentsStatus = /* GraphQL */ `
  mutation UpdateAppointmentsStatus(
    $appointmentIds: [ID!]!
    $newStatus: AppointmentStatus!
  ) {
    updateAppointmentsStatus(
      appointmentIds: $appointmentIds
      newStatus: $newStatus
    )
  }
`;
export const transferAppointment = /* GraphQL */ `
  mutation TransferAppointment(
    $appointmentId: ID!
    $currentCustomerId: ID!
    $newCustomerId: ID!
    $cascadeInvoice: Boolean!
  ) {
    transferAppointment(
      appointmentId: $appointmentId
      currentCustomerId: $currentCustomerId
      newCustomerId: $newCustomerId
      cascadeInvoice: $cascadeInvoice
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createOfficeLocation = /* GraphQL */ `
  mutation CreateOfficeLocation($input: CreateOfficeLocationInput!) {
    createOfficeLocation(input: $input) {
      id
      name
      code
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      billingAddresses {
        streetAddress
        unitNo
        country
        postalCode
        startDate
        endDate
      }
      taxStatus
      registeredName
      registrationNumber
      operatingHours
      telephones
      whatsapp
      emails
      isActive
      quickbooks {
        isEnabled
      }
      stripeAccountId
      stripeLocationId
      stripeTerminalIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateOfficeLocation = /* GraphQL */ `
  mutation UpdateOfficeLocation($input: UpdateOfficeLocationInput!) {
    updateOfficeLocation(input: $input) {
      id
      name
      code
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      billingAddresses {
        streetAddress
        unitNo
        country
        postalCode
        startDate
        endDate
      }
      taxStatus
      registeredName
      registrationNumber
      operatingHours
      telephones
      whatsapp
      emails
      isActive
      quickbooks {
        isEnabled
      }
      stripeAccountId
      stripeLocationId
      stripeTerminalIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteOfficeLocation = /* GraphQL */ `
  mutation DeleteOfficeLocation($locationId: ID!) {
    deleteOfficeLocation(locationId: $locationId) {
      id
      name
      code
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      billingAddresses {
        streetAddress
        unitNo
        country
        postalCode
        startDate
        endDate
      }
      taxStatus
      registeredName
      registrationNumber
      operatingHours
      telephones
      whatsapp
      emails
      isActive
      quickbooks {
        isEnabled
      }
      stripeAccountId
      stripeLocationId
      stripeTerminalIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      quickbooksId
      workingHours
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      quickbooksId
      workingHours
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($serviceId: ID!) {
    deleteService(serviceId: $serviceId) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      quickbooksId
      workingHours
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createClassService = /* GraphQL */ `
  mutation CreateClassService($input: CreateClassServiceInput!) {
    createClassService(input: $input) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      maxParticipants
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClassService = /* GraphQL */ `
  mutation UpdateClassService($input: UpdateClassServiceInput!) {
    updateClassService(input: $input) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      maxParticipants
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteClassService = /* GraphQL */ `
  mutation DeleteClassService($classServiceId: ID!) {
    deleteClassService(classServiceId: $classServiceId) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      maxParticipants
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPractitioner = /* GraphQL */ `
  mutation CreatePractitioner($input: CreatePractitionerInput!) {
    createPractitioner(input: $input) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      kioskOnlineBookingPageId
    }
  }
`;
export const updatePractitioner = /* GraphQL */ `
  mutation UpdatePractitioner($input: UpdatePractitionerInput!) {
    updatePractitioner(input: $input) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      kioskOnlineBookingPageId
    }
  }
`;
export const deactivateUser = /* GraphQL */ `
  mutation DeactivateUser($userId: ID!) {
    deactivateUser(userId: $userId) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      kioskOnlineBookingPageId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      viewOnlyMemberCalendars
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      kioskOnlineBookingPageId
      ... on Practitioner {
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        services {
          id
          locationId
          practitionerId
          name
          code
          description
          onlineBookingDescription
          category
          duration
          quickbooksId
          workingHours
          onlineBookingName
          onlineBookingCategory
          isActive
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        locations {
          id
          name
          code
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          taxStatus
          registeredName
          registrationNumber
          operatingHours
          telephones
          whatsapp
          emails
          isActive
          stripeAccountId
          stripeLocationId
          stripeTerminalIds
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`;
export const createPatientNote = /* GraphQL */ `
  mutation CreatePatientNote($input: CreatePatientNoteInput!) {
    createPatientNote(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updatePatientNote = /* GraphQL */ `
  mutation UpdatePatientNote($input: UpdatePatientNoteInput!) {
    updatePatientNote(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const publishPatientNote = /* GraphQL */ `
  mutation PublishPatientNote($id: ID!) {
    publishPatientNote(id: $id) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidPatientNote = /* GraphQL */ `
  mutation VoidPatientNote($input: VoidPatientNoteInput!) {
    voidPatientNote(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deletePatientNote = /* GraphQL */ `
  mutation DeletePatientNote($id: ID!) {
    deletePatientNote(id: $id) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory($input: CreateInventoryInput!) {
    createInventory(input: $input) {
      id
      locationId
      name
      code
      type
      category
      description
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      quantity
      restockThreshold
      maxThreshold
      batchQuantity
      reorderQuantity
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      dosage {
        dosage
        dosageFrequency
        dosageDuration
        dosageUsage
        dosageBase
        dosageUnit
        dosageInstructions
        dosagePrecautions
        unit
      }
      isUnlimited
      quickbooksId
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory($input: UpdateInventoryInput!) {
    updateInventory(input: $input) {
      id
      locationId
      name
      code
      type
      category
      description
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      quantity
      restockThreshold
      maxThreshold
      batchQuantity
      reorderQuantity
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      dosage {
        dosage
        dosageFrequency
        dosageDuration
        dosageUsage
        dosageBase
        dosageUnit
        dosageInstructions
        dosagePrecautions
        unit
      }
      isUnlimited
      quickbooksId
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory($id: ID!) {
    deleteInventory(id: $id) {
      id
      locationId
      name
      code
      type
      category
      description
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      quantity
      restockThreshold
      maxThreshold
      batchQuantity
      reorderQuantity
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      dosage {
        dosage
        dosageFrequency
        dosageDuration
        dosageUsage
        dosageBase
        dosageUnit
        dosageInstructions
        dosagePrecautions
        unit
      }
      isUnlimited
      quickbooksId
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const selfServiceCreateInvoice = /* GraphQL */ `
  mutation SelfServiceCreateInvoice($input: SelfServiceCreateInvoiceInput!) {
    selfServiceCreateInvoice(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidInvoice = /* GraphQL */ `
  mutation VoidInvoice($input: VoidInvoiceInput!) {
    voidInvoice(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidAdjustment = /* GraphQL */ `
  mutation VoidAdjustment($input: VoidAdjustmentInput!) {
    voidAdjustment(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidInvoiceItem = /* GraphQL */ `
  mutation VoidInvoiceItem($input: VoidInvoiceItemInput!) {
    voidInvoiceItem(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateInvoiceNote = /* GraphQL */ `
  mutation UpdateInvoiceNote($invoiceId: ID!, $note: String!) {
    updateInvoiceNote(invoiceId: $invoiceId, note: $note) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateInvoiceReference = /* GraphQL */ `
  mutation UpdateInvoiceReference($invoiceId: ID!, $reference: String!) {
    updateInvoiceReference(invoiceId: $invoiceId, reference: $reference) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateInvoiceItemDescription = /* GraphQL */ `
  mutation UpdateInvoiceItemDescription(
    $input: UpdateInvoiceItemDescriptionInput!
  ) {
    updateInvoiceItemDescription(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const transferInvoice = /* GraphQL */ `
  mutation TransferInvoice(
    $invoiceId: ID!
    $currentCustomerId: ID!
    $newCustomerId: ID!
  ) {
    transferInvoice(
      invoiceId: $invoiceId
      currentCustomerId: $currentCustomerId
      newCustomerId: $newCustomerId
    ) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const adminUpdateInvoice = /* GraphQL */ `
  mutation AdminUpdateInvoice($input: AdminUpdateInvoiceInput!) {
    adminUpdateInvoice(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      invoiceId
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoice {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      corporateId
      type
      mode
      status
      paymentTime
      settlementTime
      amountPaid {
        amount
        currency
        precision
        scale
      }
      allocations {
        invoiceItemId
      }
      reference
      note
      paymentNumber
      isInsurance
      isRefunded
      voidEffectiveAt
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidPayment = /* GraphQL */ `
  mutation VoidPayment($input: VoidPaymentInput!) {
    voidPayment(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      invoiceId
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoice {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      corporateId
      type
      mode
      status
      paymentTime
      settlementTime
      amountPaid {
        amount
        currency
        precision
        scale
      }
      allocations {
        invoiceItemId
      }
      reference
      note
      paymentNumber
      isInsurance
      isRefunded
      voidEffectiveAt
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const selfServiceRequestPayment = /* GraphQL */ `
  mutation SelfServiceRequestPayment($input: SelfServiceRequestPaymentInput!) {
    selfServiceRequestPayment(input: $input) {
      stripeUrl
      payment {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const storeSystemSettings = /* GraphQL */ `
  mutation StoreSystemSettings($input: CreateSystemSettingsInput!) {
    storeSystemSettings(input: $input) {
      id
      invoice {
        invoicePrefix
        dueDatePeriod
        logoUrl
        invoiceTemplate
      }
      communication {
        preferMedium
      }
      medicalCertificate {
        nextSerialNumber
      }
      apiIntegrations {
        provider
        description
        baseUrl
        apiKey
        clientId
        clientSecret
      }
      timezone
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const storeGlobalUISettings = /* GraphQL */ `
  mutation StoreGlobalUISettings($input: CreateUISettingsInput!) {
    storeGlobalUISettings(input: $input) {
      id
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const storeMyUISettings = /* GraphQL */ `
  mutation StoreMyUISettings($input: CreateUISettingsInput!) {
    storeMyUISettings(input: $input) {
      global {
        id
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      user {
        id
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createCommunication = /* GraphQL */ `
  mutation CreateCommunication($input: CreateCommunicationInput!) {
    createCommunication(input: $input) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updateCommunication = /* GraphQL */ `
  mutation UpdateCommunication($input: UpdateCommunicationInput!) {
    updateCommunication(input: $input) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const voidCommunication = /* GraphQL */ `
  mutation VoidCommunication($id: ID!, $voidReason: String!) {
    voidCommunication(id: $id, voidReason: $voidReason) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const requestDocumentUpload = /* GraphQL */ `
  mutation RequestDocumentUpload($input: CreateDocumentUploadInput!) {
    requestDocumentUpload(input: $input) {
      id
      url
    }
  }
`;
export const requestDocumentDownload = /* GraphQL */ `
  mutation RequestDocumentDownload($documentId: ID!) {
    requestDocumentDownload(documentId: $documentId)
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      id
      locationId
      practitionerId
      patientId
      appointmentId
      invoiceId
      paymentId
      type
      fileName
      s3Name
      s3Bucket
      uploadStatus
      metadata
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const patientConfirmAppointment = /* GraphQL */ `
  mutation PatientConfirmAppointment($token: String) {
    patientConfirmAppointment(token: $token) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createCorporate = /* GraphQL */ `
  mutation CreateCorporate($input: CreateCorporateInput!) {
    createCorporate(input: $input) {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
      }
      specialPricing {
        itemId
        itemType
        category
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateCorporate = /* GraphQL */ `
  mutation UpdateCorporate($input: UpdateCorporateInput!) {
    updateCorporate(input: $input) {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
      }
      specialPricing {
        itemId
        itemType
        category
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteCorporate = /* GraphQL */ `
  mutation DeleteCorporate($id: ID!) {
    deleteCorporate(id: $id) {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
      }
      specialPricing {
        itemId
        itemType
        category
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      entityName
      personName
      entityType
      category
      email
      mobile
      officePhone
      fax
      preferredModeOfContact
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      entityName
      personName
      entityType
      category
      email
      mobile
      officePhone
      fax
      preferredModeOfContact
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
      entityName
      personName
      entityType
      category
      email
      mobile
      officePhone
      fax
      preferredModeOfContact
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createMedicalCertificate = /* GraphQL */ `
  mutation CreateMedicalCertificate($input: CreateMedicalCertificateInput!) {
    createMedicalCertificate(input: $input) {
      id
      serialNumber
      content
      patientId
      appointmentId
      locationId
      practitionerId
      fromDate
      toDate
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const voidMedicalCertificate = /* GraphQL */ `
  mutation VoidMedicalCertificate($input: VoidMedicalCertificateInput!) {
    voidMedicalCertificate(input: $input) {
      id
      serialNumber
      content
      patientId
      appointmentId
      locationId
      practitionerId
      fromDate
      toDate
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const deleteMedicalCertificate = /* GraphQL */ `
  mutation DeleteMedicalCertificate($id: ID!) {
    deleteMedicalCertificate(id: $id) {
      id
      serialNumber
      content
      patientId
      appointmentId
      locationId
      practitionerId
      fromDate
      toDate
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createTimeOff = /* GraphQL */ `
  mutation CreateTimeOff($input: CreateTimeOffInput!) {
    createTimeOff(input: $input) {
      id
      practitionerId
      locationId
      locationCode
      title
      description
      eventStartTime
      eventEndTime
      isRecurring
      recurringFrequency
      recurringEndDate
      recurringInstances
      type
      category
      colourCode
      allDay
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateTimeOff = /* GraphQL */ `
  mutation UpdateTimeOff($input: UpdateTimeOffInput!) {
    updateTimeOff(input: $input) {
      id
      practitionerId
      locationId
      locationCode
      title
      description
      eventStartTime
      eventEndTime
      isRecurring
      recurringFrequency
      recurringEndDate
      recurringInstances
      type
      category
      colourCode
      allDay
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteTimeOff = /* GraphQL */ `
  mutation DeleteTimeOff($id: ID!) {
    deleteTimeOff(id: $id) {
      id
      practitionerId
      locationId
      locationCode
      title
      description
      eventStartTime
      eventEndTime
      isRecurring
      recurringFrequency
      recurringEndDate
      recurringInstances
      type
      category
      colourCode
      allDay
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPatientSignature = /* GraphQL */ `
  mutation CreatePatientSignature($input: CreatePatientSignatureInput!) {
    createPatientSignature(input: $input) {
      id
      serialNumber
      documentContent
      documentNameSigned
      patientSignatureContent
      practitionerSignatureContent
      patientId
      appointmentId
      locationId
      practitionerId
      additionalSignatories {
        role
        name
        signatureContent
      }
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updatePatientSignature = /* GraphQL */ `
  mutation UpdatePatientSignature($input: UpdatePatientSignatureInput!) {
    updatePatientSignature(input: $input) {
      id
      serialNumber
      documentContent
      documentNameSigned
      patientSignatureContent
      practitionerSignatureContent
      patientId
      appointmentId
      locationId
      practitionerId
      additionalSignatories {
        role
        name
        signatureContent
      }
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const voidPatientSignature = /* GraphQL */ `
  mutation VoidPatientSignature($input: VoidPatientSignatureInput!) {
    voidPatientSignature(input: $input) {
      id
      serialNumber
      documentContent
      documentNameSigned
      patientSignatureContent
      practitionerSignatureContent
      patientId
      appointmentId
      locationId
      practitionerId
      additionalSignatories {
        role
        name
        signatureContent
      }
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const deletePatientSignature = /* GraphQL */ `
  mutation DeletePatientSignature($id: ID!) {
    deletePatientSignature(id: $id) {
      id
      serialNumber
      documentContent
      documentNameSigned
      patientSignatureContent
      practitionerSignatureContent
      patientId
      appointmentId
      locationId
      practitionerId
      additionalSignatories {
        role
        name
        signatureContent
      }
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createOnlineBookingPage = /* GraphQL */ `
  mutation CreateOnlineBookingPage($input: CreateOnlineBookingPageInput!) {
    createOnlineBookingPage(input: $input) {
      id
      tenantId
      tenantCode
      name
      urlSlug
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerIds
      practitioners {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationIds
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      appointmentHoldTime
      thirdPartyTools
      createInvoiceUpfront
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateOnlineBookingPage = /* GraphQL */ `
  mutation UpdateOnlineBookingPage($input: UpdateOnlineBookingPageInput!) {
    updateOnlineBookingPage(input: $input) {
      id
      tenantId
      tenantCode
      name
      urlSlug
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerIds
      practitioners {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationIds
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      appointmentHoldTime
      thirdPartyTools
      createInvoiceUpfront
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteOnlineBookingPage = /* GraphQL */ `
  mutation DeleteOnlineBookingPage($id: ID!) {
    deleteOnlineBookingPage(id: $id) {
      id
      tenantId
      tenantCode
      name
      urlSlug
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerIds
      practitioners {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationIds
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      appointmentHoldTime
      thirdPartyTools
      createInvoiceUpfront
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createOnlineBookingTransaction = /* GraphQL */ `
  mutation CreateOnlineBookingTransaction(
    $input: CreateOnlineBookingTransactionInput!
  ) {
    createOnlineBookingTransaction(input: $input) {
      id
      onlineBookingPageId
      patientName
      patientEmail
      patientMobile
      serviceId
      classServiceId
      locationId
      startTime
      endTime
      patientId
      classAppointmentId
      appointmentId
      originalPrice {
        amount
        currency
        precision
        scale
      }
      adjustmentCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      status
      paymentRequired
      paymentStatus
      stripeChargeIds
      customFields
      urlQueryParams
      communicationId
      paymentIds
      paidAt
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
      stripeIntegrated
      stripeSetupIntentUrl
      multiplePatients
    }
  }
`;
export const requestOnlineBookingTransactionPayment = /* GraphQL */ `
  mutation RequestOnlineBookingTransactionPayment(
    $tenant: String!
    $bookingTransaction: ID!
  ) {
    requestOnlineBookingTransactionPayment(
      tenant: $tenant
      bookingTransaction: $bookingTransaction
    )
  }
`;
export const createOnlineBookingRequest = /* GraphQL */ `
  mutation CreateOnlineBookingRequest(
    $input: CreateOnlineBookingRequestInput!
  ) {
    createOnlineBookingRequest(input: $input) {
      id
      locationId
      customerName
      customerMobile
      customerEmail
      requestRemarks
      onlineBookingPageId
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateOnlineBookingRequestStatus = /* GraphQL */ `
  mutation UpdateOnlineBookingRequestStatus($id: ID!, $status: String!) {
    updateOnlineBookingRequestStatus(id: $id, status: $status) {
      id
      locationId
      customerName
      customerMobile
      customerEmail
      requestRemarks
      onlineBookingPageId
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createNoticeBoardMessage = /* GraphQL */ `
  mutation CreateNoticeBoardMessage($input: CreateNoticeBoardMessageInput!) {
    createNoticeBoardMessage(input: $input) {
      id
      locationId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateNoticeBoardMessage = /* GraphQL */ `
  mutation UpdateNoticeBoardMessage($input: UpdateNoticeBoardMessageInput!) {
    updateNoticeBoardMessage(input: $input) {
      id
      locationId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteNoticeBoardMessage = /* GraphQL */ `
  mutation DeleteNoticeBoardMessage($id: ID!) {
    deleteNoticeBoardMessage(id: $id) {
      id
      locationId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const requestManualPatientStripeIntegration = /* GraphQL */ `
  mutation RequestManualPatientStripeIntegration(
    $input: ManualPatientStripeIntegrationInput!
  ) {
    requestManualPatientStripeIntegration(input: $input) {
      result
    }
  }
`;
export const removePatientStripePaymentMethod = /* GraphQL */ `
  mutation RemovePatientStripePaymentMethod(
    $input: RemovePatientStripePaymentMethodInput!
  ) {
    removePatientStripePaymentMethod(input: $input) {
      result
    }
  }
`;
export const createGoogleIntegrationLink = /* GraphQL */ `
  mutation CreateGoogleIntegrationLink(
    $input: CreateGoogleCalendarIntegrationInput!
  ) {
    createGoogleIntegrationLink(input: $input) {
      result
    }
  }
`;
export const integrateGoogleCalendar = /* GraphQL */ `
  mutation IntegrateGoogleCalendar($locationId: ID, $code: String) {
    integrateGoogleCalendar(locationId: $locationId, code: $code)
  }
`;
export const createCommunicationTemplate = /* GraphQL */ `
  mutation CreateCommunicationTemplate(
    $input: CreateCommunicationTemplateInput!
  ) {
    createCommunicationTemplate(input: $input) {
      id
      name
      type
      description
      template
      from {
        email
        name
      }
      replyTo {
        email
        name
      }
      bcc
      cc
      emailSubject
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateCommunicationTemplate = /* GraphQL */ `
  mutation UpdateCommunicationTemplate(
    $input: UpdateCommunicationTemplateInput!
  ) {
    updateCommunicationTemplate(input: $input) {
      id
      name
      type
      description
      template
      from {
        email
        name
      }
      replyTo {
        email
        name
      }
      bcc
      cc
      emailSubject
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateUninitializedPatient = /* GraphQL */ `
  mutation UpdateUninitializedPatient(
    $input: UpdateUninitializedPatientInput!
  ) {
    updateUninitializedPatient(input: $input)
  }
`;
export const createClassAppointment = /* GraphQL */ `
  mutation CreateClassAppointment($input: CreateClassAppointmentInput!) {
    createClassAppointment(input: $input) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClassAppointment = /* GraphQL */ `
  mutation UpdateClassAppointment($input: UpdateClassAppointmentInput!) {
    updateClassAppointment(input: $input) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClassAppointmentSeries = /* GraphQL */ `
  mutation UpdateClassAppointmentSeries(
    $input: UpdateClassAppointmentSeriesInput!
  ) {
    updateClassAppointmentSeries(input: $input) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const cancelClassAppointment = /* GraphQL */ `
  mutation CancelClassAppointment($input: CancelClassAppointmentInput!) {
    cancelClassAppointment(input: $input) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const cancelClassAppointmentSeries = /* GraphQL */ `
  mutation CancelClassAppointmentSeries($input: CancelClassAppointmentInput!) {
    cancelClassAppointmentSeries(input: $input) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const registerClassAppointmentPatients = /* GraphQL */ `
  mutation RegisterClassAppointmentPatients(
    $patientInputs: [RegisterClassAppointmentPatientInput!]!
  ) {
    registerClassAppointmentPatients(patientInputs: $patientInputs) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const unregisterClassAppointmentPatients = /* GraphQL */ `
  mutation UnregisterClassAppointmentPatients(
    $patientInputs: [UnregisterClassAppointmentPatientInput!]!
  ) {
    unregisterClassAppointmentPatients(patientInputs: $patientInputs) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        kioskOnlineBookingPageId
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPass = /* GraphQL */ `
  mutation CreatePass($input: CreatePassInput!) {
    createPass(input: $input) {
      id
      name
      code
      description
      eligibleClassServices
      eligibleServices
      type
      maxRedemptionsPerDay
      maxRedemptionsPerWeek
      maxRedemptionsPerMonth
      validityPeriod
      totalSessions
      price {
        amount
        currency
        precision
        scale
      }
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      decrementCancellations
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updatePass = /* GraphQL */ `
  mutation UpdatePass($input: UpdatePassInput!) {
    updatePass(input: $input) {
      id
      name
      code
      description
      eligibleClassServices
      eligibleServices
      type
      maxRedemptionsPerDay
      maxRedemptionsPerWeek
      maxRedemptionsPerMonth
      validityPeriod
      totalSessions
      price {
        amount
        currency
        precision
        scale
      }
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      decrementCancellations
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const purchasePass = /* GraphQL */ `
  mutation PurchasePass($input: CreatePassPurchaseInput!) {
    purchasePass(input: $input) {
      id
      patientId
      corporateId
      practitionerId
      salePersonId
      passId
      invoiceId
      purchaseDate
      totalPassesPurchased
      totalPassesAssigned
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const voidPassPurchase = /* GraphQL */ `
  mutation VoidPassPurchase($purchaseId: ID!, $reason: String!) {
    voidPassPurchase(purchaseId: $purchaseId, reason: $reason) {
      id
      patientId
      corporateId
      practitionerId
      salePersonId
      passId
      invoiceId
      purchaseDate
      totalPassesPurchased
      totalPassesAssigned
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const updatePatientPass = /* GraphQL */ `
  mutation UpdatePatientPass($input: UpdatePatientPassInput!) {
    updatePatientPass(input: $input) {
      id
      ownerId
      ownerCorporateId
      patientIds
      passId
      passName
      passCode
      purchaseId
      invoiceId
      purchasedLocationId
      eligibleClassServices
      eligibleServices
      status
      note
      totalRedemptions
      redemptionsMade
      redemptionsRemaining
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      expiryDate
      assignedDate
      purchaseDate
      purchasedLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passRedemptions {
        id
        patientPassId
        patientId
        locationId
        appointmentId
        practitionerId
        serviceId
        classId
        classServiceId
        invoiceId
        redemptionNote
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPatientPass = /* GraphQL */ `
  mutation CreatePatientPass($input: CreatePatientPassInput!) {
    createPatientPass(input: $input) {
      id
      ownerId
      ownerCorporateId
      patientIds
      passId
      passName
      passCode
      purchaseId
      invoiceId
      purchasedLocationId
      eligibleClassServices
      eligibleServices
      status
      note
      totalRedemptions
      redemptionsMade
      redemptionsRemaining
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      expiryDate
      assignedDate
      purchaseDate
      purchasedLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passRedemptions {
        id
        patientPassId
        patientId
        locationId
        appointmentId
        practitionerId
        serviceId
        classId
        classServiceId
        invoiceId
        redemptionNote
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidPatientPass = /* GraphQL */ `
  mutation VoidPatientPass($id: ID!, $reason: String!) {
    voidPatientPass(id: $id, reason: $reason) {
      id
      ownerId
      ownerCorporateId
      patientIds
      passId
      passName
      passCode
      purchaseId
      invoiceId
      purchasedLocationId
      eligibleClassServices
      eligibleServices
      status
      note
      totalRedemptions
      redemptionsMade
      redemptionsRemaining
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      expiryDate
      assignedDate
      purchaseDate
      purchasedLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passRedemptions {
        id
        patientPassId
        patientId
        locationId
        appointmentId
        practitionerId
        serviceId
        classId
        classServiceId
        invoiceId
        redemptionNote
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const redeemPass = /* GraphQL */ `
  mutation RedeemPass($input: CreatePassRedemptionInput!) {
    redeemPass(input: $input) {
      id
      patientPassId
      patientId
      locationId
      appointmentId
      practitionerId
      serviceId
      classId
      classServiceId
      invoiceId
      originalRedemptionValue {
        amount
        currency
        precision
        scale
      }
      finalRedemptionValue {
        amount
        currency
        precision
        scale
      }
      redemptionNote
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const voidPassRedemption = /* GraphQL */ `
  mutation VoidPassRedemption($redemptionId: ID!, $reason: String!) {
    voidPassRedemption(redemptionId: $redemptionId, reason: $reason) {
      id
      patientPassId
      patientId
      locationId
      appointmentId
      practitionerId
      serviceId
      classId
      classServiceId
      invoiceId
      originalRedemptionValue {
        amount
        currency
        precision
        scale
      }
      finalRedemptionValue {
        amount
        currency
        precision
        scale
      }
      redemptionNote
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createCustomerFeedback = /* GraphQL */ `
  mutation CreateCustomerFeedback($input: CreateCustomerFeedbackInput!) {
    createCustomerFeedback(input: $input) {
      id
      locationId
      customerId
      content {
        id
        type
        name
        text
        options
        value
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const voidCustomerFeedback = /* GraphQL */ `
  mutation VoidCustomerFeedback($id: ID!, $voidReason: String!) {
    voidCustomerFeedback(id: $id, voidReason: $voidReason) {
      id
      locationId
      customerId
      content {
        id
        type
        name
        text
        options
        value
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createCustomerMemo = /* GraphQL */ `
  mutation CreateCustomerMemo($input: CreateCustomerMemoInput!) {
    createCustomerMemo(input: $input) {
      id
      locationId
      customerId
      parentMemoId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteCustomerMemo = /* GraphQL */ `
  mutation DeleteCustomerMemo($id: ID!) {
    deleteCustomerMemo(id: $id) {
      id
      locationId
      customerId
      parentMemoId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock($input: CreateBlockInput!) {
    createBlock(input: $input) {
      id
      locationId
      customerId
      appointmentId
      flowActivityId
      classAppointmentId
      name
      category
      subCategory
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock($input: UpdateBlockInput!) {
    updateBlock(input: $input) {
      id
      locationId
      customerId
      appointmentId
      flowActivityId
      classAppointmentId
      name
      category
      subCategory
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const fulfilBlock = /* GraphQL */ `
  mutation FulfilBlock($id: ID!) {
    fulfilBlock(id: $id) {
      id
      locationId
      customerId
      appointmentId
      flowActivityId
      classAppointmentId
      name
      category
      subCategory
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock($id: ID!) {
    deleteBlock(id: $id) {
      id
      locationId
      customerId
      appointmentId
      flowActivityId
      classAppointmentId
      name
      category
      subCategory
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createStation = /* GraphQL */ `
  mutation CreateStation($input: CreateStationInput!) {
    createStation(input: $input) {
      id
      locationId
      providerId
      name
      capacity
      customerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      isInSession
      isActive
      activitySessionId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateStation = /* GraphQL */ `
  mutation UpdateStation($input: UpdateStationInput!) {
    updateStation(input: $input) {
      id
      locationId
      providerId
      name
      capacity
      customerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      isInSession
      isActive
      activitySessionId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteStation = /* GraphQL */ `
  mutation DeleteStation($id: ID!) {
    deleteStation(id: $id) {
      id
      locationId
      providerId
      name
      capacity
      customerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      isInSession
      isActive
      activitySessionId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const subscribeGoogleEventListener = /* GraphQL */ `
  mutation SubscribeGoogleEventListener($providerId: ID!) {
    subscribeGoogleEventListener(providerId: $providerId)
  }
`;
export const upsertResourceSchedule = /* GraphQL */ `
  mutation UpsertResourceSchedule($input: UpsertResourceScheduleInput!) {
    upsertResourceSchedule(input: $input) {
      id
      resourceId
      resourceType
      year
      days {
        day
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const putResourceScheduleDay = /* GraphQL */ `
  mutation PutResourceScheduleDay($input: PutResourceScheduleDayInput!) {
    putResourceScheduleDay(input: $input) {
      id
      resourceId
      resourceType
      year
      days {
        day
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteResourceScheduleDay = /* GraphQL */ `
  mutation DeleteResourceScheduleDay(
    $resourceId: ID!
    $resourceType: String!
    $year: Int!
    $day: Int!
  ) {
    deleteResourceScheduleDay(
      resourceId: $resourceId
      resourceType: $resourceType
      year: $year
      day: $day
    ) {
      id
      resourceId
      resourceType
      year
      days {
        day
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const customerUpdateAppointment = /* GraphQL */ `
  mutation CustomerUpdateAppointment(
    $tenantId: ID!
    $appointmentId: ID!
    $appointmentStartTime: AWSDateTime!
    $appointmentEndTime: AWSDateTime!
  ) {
    customerUpdateAppointment(
      tenantId: $tenantId
      appointmentId: $appointmentId
      appointmentStartTime: $appointmentStartTime
      appointmentEndTime: $appointmentEndTime
    )
  }
`;
export const customerCancelAppointment = /* GraphQL */ `
  mutation CustomerCancelAppointment(
    $tenantId: ID!
    $appointmentId: ID!
    $cancelReason: String!
  ) {
    customerCancelAppointment(
      tenantId: $tenantId
      appointmentId: $appointmentId
      cancelReason: $cancelReason
    )
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode($input: CreatePromoCodeInput!) {
    createPromoCode(input: $input) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode($input: UpdatePromoCodeInput!) {
    updatePromoCode(input: $input) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode($id: ID!) {
    deletePromoCode(id: $id) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidPromoCodeRedemption = /* GraphQL */ `
  mutation VoidPromoCodeRedemption($id: ID!, $reason: String!) {
    voidPromoCodeRedemption(id: $id, reason: $reason) {
      id
      promoCodeId
      promoCodeCode
      promoCodeType
      locationId
      customerId
      customerName
      redemptionCount
      onlineBookingPageId
      onlineBookingTransactionId
      appointmentId
      invoiceId
      invoiceNumber
      providerId
      providerName
      serviceId
      serviceName
      inventoryId
      inventoryName
      classServiceId
      classServiceName
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const createFlowActivitySession = /* GraphQL */ `
  mutation CreateFlowActivitySession($input: CreateFlowActivitySessionInput!) {
    createFlowActivitySession(input: $input) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const updateFlowActivitySessionStations = /* GraphQL */ `
  mutation UpdateFlowActivitySessionStations(
    $input: UpdateFlowActivitySessionStationsInput!
  ) {
    updateFlowActivitySessionStations(input: $input) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const updateFlowActivitySessionCustomerOrders = /* GraphQL */ `
  mutation UpdateFlowActivitySessionCustomerOrders(
    $activitySessionId: ID!
    $customerActivityIds: [ID!]!
  ) {
    updateFlowActivitySessionCustomerOrders(
      activitySessionId: $activitySessionId
      customerActivityIds: $customerActivityIds
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const startFlowActivitySession = /* GraphQL */ `
  mutation StartFlowActivitySession($activitySessionId: ID!) {
    startFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const pauseFlowActivitySession = /* GraphQL */ `
  mutation PauseFlowActivitySession($activitySessionId: ID!) {
    pauseFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const resumeFlowActivitySession = /* GraphQL */ `
  mutation ResumeFlowActivitySession($activitySessionId: ID!) {
    resumeFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const completeFlowActivitySession = /* GraphQL */ `
  mutation CompleteFlowActivitySession($activitySessionId: ID!) {
    completeFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const checkInFlowActivitySessionCustomerActivity = /* GraphQL */ `
  mutation CheckInFlowActivitySessionCustomerActivity(
    $activityId: ID!
    $customerShortCode: String!
  ) {
    checkInFlowActivitySessionCustomerActivity(
      activityId: $activityId
      customerShortCode: $customerShortCode
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const addFlowActivitySessionCustomerActivity = /* GraphQL */ `
  mutation AddFlowActivitySessionCustomerActivity(
    $activitySessionId: ID!
    $customerActivityId: ID!
    $stationId: ID!
  ) {
    addFlowActivitySessionCustomerActivity(
      activitySessionId: $activitySessionId
      customerActivityId: $customerActivityId
      stationId: $stationId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const removeFlowActivitySessionCustomerActivity = /* GraphQL */ `
  mutation RemoveFlowActivitySessionCustomerActivity(
    $activitySessionId: ID!
    $customerActivityId: ID!
  ) {
    removeFlowActivitySessionCustomerActivity(
      activitySessionId: $activitySessionId
      customerActivityId: $customerActivityId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const moveCustomerActivityBackToWaiting = /* GraphQL */ `
  mutation MoveCustomerActivityBackToWaiting(
    $activitySessionId: ID!
    $customerActivityId: ID!
  ) {
    moveCustomerActivityBackToWaiting(
      activitySessionId: $activitySessionId
      customerActivityId: $customerActivityId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const processFlowActivitySession = /* GraphQL */ `
  mutation ProcessFlowActivitySession($activitySessionId: ID!) {
    processFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const forceRefreshFlowActivitySession = /* GraphQL */ `
  mutation ForceRefreshFlowActivitySession($activitySessionId: ID!) {
    forceRefreshFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const backendForceRefreshFlowActivitySession = /* GraphQL */ `
  mutation BackendForceRefreshFlowActivitySession(
    $tenantId: ID!
    $activitySessionId: ID!
  ) {
    backendForceRefreshFlowActivitySession(
      tenantId: $tenantId
      activitySessionId: $activitySessionId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const createAppointmentPlanTemplate = /* GraphQL */ `
  mutation CreateAppointmentPlanTemplate(
    $input: CreateAppointmentPlanTemplateInput!
  ) {
    createAppointmentPlanTemplate(input: $input) {
      id
      name
      description
      planType
      allowedServices {
        id
        name
      }
      allowedClassServices {
        id
        name
      }
      schedule {
        periodType
        periodFrequency
        totalPeriods
      }
      milestones {
        appointmentSequence
        milestone
      }
      totalSessions
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateAppointmentPlanTemplate = /* GraphQL */ `
  mutation UpdateAppointmentPlanTemplate(
    $input: UpdateAppointmentPlanTemplateInput!
  ) {
    updateAppointmentPlanTemplate(input: $input) {
      id
      name
      description
      planType
      allowedServices {
        id
        name
      }
      allowedClassServices {
        id
        name
      }
      schedule {
        periodType
        periodFrequency
        totalPeriods
      }
      milestones {
        appointmentSequence
        milestone
      }
      totalSessions
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteAppointmentPlanTemplate = /* GraphQL */ `
  mutation DeleteAppointmentPlanTemplate($id: ID!) {
    deleteAppointmentPlanTemplate(id: $id) {
      id
      name
      description
      planType
      allowedServices {
        id
        name
      }
      allowedClassServices {
        id
        name
      }
      schedule {
        periodType
        periodFrequency
        totalPeriods
      }
      milestones {
        appointmentSequence
        milestone
      }
      totalSessions
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createAppointmentPlan = /* GraphQL */ `
  mutation CreateAppointmentPlan($input: CreateAppointmentPlanInput!) {
    createAppointmentPlan(input: $input) {
      id
      name
      description
      templateId
      customerId
      planType
      startAt
      completedAt
      isActive
      appointmentIds
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      completedAppointmentIds
      cancelledAppointmentIds
      missedAppointmentIds
      upcomingAppointmentIds
      totalSessions
      createdAt
      createdBy
      updatedAt
      updatedBy
      version
    }
  }
`;
export const updateAppointmentPlan = /* GraphQL */ `
  mutation UpdateAppointmentPlan($input: UpdateAppointmentPlanInput!) {
    updateAppointmentPlan(input: $input) {
      id
      name
      description
      templateId
      customerId
      planType
      startAt
      completedAt
      isActive
      appointmentIds
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      completedAppointmentIds
      cancelledAppointmentIds
      missedAppointmentIds
      upcomingAppointmentIds
      totalSessions
      createdAt
      createdBy
      updatedAt
      updatedBy
      version
    }
  }
`;
export const deleteAppointmentPlan = /* GraphQL */ `
  mutation DeleteAppointmentPlan($id: ID!) {
    deleteAppointmentPlan(id: $id) {
      id
      name
      description
      templateId
      customerId
      planType
      startAt
      completedAt
      isActive
      appointmentIds
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        reservationCode
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      completedAppointmentIds
      cancelledAppointmentIds
      missedAppointmentIds
      upcomingAppointmentIds
      totalSessions
      createdAt
      createdBy
      updatedAt
      updatedBy
      version
    }
  }
`;
export const createCreditNote = /* GraphQL */ `
  mutation CreateCreditNote($input: CreateCreditNoteInput!) {
    createCreditNote(input: $input) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createCreditNoteStripePayment = /* GraphQL */ `
  mutation CreateCreditNoteStripePayment(
    $input: CreateCreditNoteStripePaymentInput!
  ) {
    createCreditNoteStripePayment(input: $input) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateCreditNoteReason = /* GraphQL */ `
  mutation UpdateCreditNoteReason($id: ID!, $reason: String!) {
    updateCreditNoteReason(id: $id, reason: $reason) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateCreditNoteNote = /* GraphQL */ `
  mutation UpdateCreditNoteNote($id: ID!, $note: String!) {
    updateCreditNoteNote(id: $id, note: $note) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const voidCreditNote = /* GraphQL */ `
  mutation VoidCreditNote($input: VoidCreditNoteInput!) {
    voidCreditNote(input: $input) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createStripeTerminalConnectionToken = /* GraphQL */ `
  mutation CreateStripeTerminalConnectionToken($locationId: ID!) {
    createStripeTerminalConnectionToken(locationId: $locationId)
  }
`;
export const createStripeTerminalPaymentIntent = /* GraphQL */ `
  mutation CreateStripeTerminalPaymentIntent($paymentId: ID!) {
    createStripeTerminalPaymentIntent(paymentId: $paymentId)
  }
`;
export const mergeCustomerAccounts = /* GraphQL */ `
  mutation MergeCustomerAccounts(
    $retainingCustomerId: ID!
    $removingCustomerId: ID!
  ) {
    mergeCustomerAccounts(
      retainingCustomerId: $retainingCustomerId
      removingCustomerId: $removingCustomerId
    )
  }
`;
export const createProviderNoteTemplate = /* GraphQL */ `
  mutation CreateProviderNoteTemplate($input: CreateProviderNoteTemplate!) {
    createProviderNoteTemplate(input: $input) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateProviderNoteTemplate = /* GraphQL */ `
  mutation UpdateProviderNoteTemplate($input: UpdateProviderNoteTemplate!) {
    updateProviderNoteTemplate(input: $input) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteProviderNoteTemplate = /* GraphQL */ `
  mutation DeleteProviderNoteTemplate($id: ID!) {
    deleteProviderNoteTemplate(id: $id) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const adminCreateProviderNoteTemplate = /* GraphQL */ `
  mutation AdminCreateProviderNoteTemplate(
    $input: AdminCreateProviderNoteTemplate!
  ) {
    adminCreateProviderNoteTemplate(input: $input) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const adminUpdateProviderNoteTemplate = /* GraphQL */ `
  mutation AdminUpdateProviderNoteTemplate(
    $input: AdminUpdateProviderNoteTemplate!
  ) {
    adminUpdateProviderNoteTemplate(input: $input) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const adminDeleteProviderNoteTemplate = /* GraphQL */ `
  mutation AdminDeleteProviderNoteTemplate($id: ID!) {
    adminDeleteProviderNoteTemplate(id: $id) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPublicDocumentUpload = /* GraphQL */ `
  mutation CreatePublicDocumentUpload(
    $input: CreatePublicDocumentUploadInput!
  ) {
    createPublicDocumentUpload(input: $input) {
      id
      url
      fields
    }
  }
`;
export const adminCreatePublicDocumentUpload = /* GraphQL */ `
  mutation AdminCreatePublicDocumentUpload(
    $input: AdminCreatePublicDocumentUploadInput!
  ) {
    adminCreatePublicDocumentUpload(input: $input) {
      id
      url
      fields
    }
  }
`;
export const updateUserViewOnlyMemberCalendars = /* GraphQL */ `
  mutation UpdateUserViewOnlyMemberCalendars($userId: ID!, $memberIds: [ID]!) {
    updateUserViewOnlyMemberCalendars(userId: $userId, memberIds: $memberIds) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      kioskOnlineBookingPageId
    }
  }
`;
